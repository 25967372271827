@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif, 'Lora', ;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.name {
    position: absolute;
    top: 30%;
    left: 15%;
    color: white;
    font-size: max(1.5em, 5vw);
}

.header-wrapper {
    position: relative;
}

.wall {
    width: 100%;
    height: 100%;
}
.social-part .fa{
    padding-right:20px;
    margin-right: 20px;
    max-width: 30px;
}

.brand-name {
    color:black !important;
    text-decoration: none !important;
}

.social-part .logo-nav {
    max-width: 30px;
    -webkit-filter: invert(40%) sepia(0%) saturate(0%) hue-rotate(0deg) ;
            filter: invert(40%) sepia(0%) saturate(0%) hue-rotate(0deg) ;
}

.brand {
    padding-top: 1em;
}

.social-part .logo-nav:hover {
    -webkit-filter: invert(0%) sepia(0%) saturate(50%) hue-rotate(86deg) ;
            filter: invert(0%) sepia(0%) saturate(50%) hue-rotate(86deg) ;
}
.social-part .logo-git {
    padding-left: .1em;
    padding-top: .2em;
    -webkit-filter: invert(40%) sepia(0%) saturate(0%) hue-rotate(0deg) ;
            filter: invert(40%) sepia(0%) saturate(0%) hue-rotate(0deg) ;
}

.social-part .logo-git:hover {
    -webkit-filter: invert(0%) sepia(0%) saturate(50%) hue-rotate(86deg) ;
            filter: invert(0%) sepia(0%) saturate(50%) hue-rotate(86deg) ;
}

.home {
    font-family: "Georgia";
}

.home .home-nav {
    color : black !important;
}

.container {
    padding-top: 2em;
    font-size: min(max(1vw, 1px), 1px);
}

.title {
    font-size: 1.2em;
}

.about {
    padding-bottom: 3vh;
}

.buttons-container {
    margin-bottom: 3vh;
    display: flex;
}

.proj-btn {
    background-color: rgb(85, 122, 149, 0.3) !important;
    margin-bottom: 1vh;
}

.thoughts-btn {
    margin-left: 1vw;
    background-color: rgb(85, 122, 149, 0.3) !important;
    margin-bottom: 1vh;
}

.proj-btn:hover,
.thoughts-btn:hover {
    color: white !important;
    background-color: rgb(85, 122, 149) !important;
}

.card {
    border: 0em;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
}

.card-title {
    font-size: 1em;
    position: relative;
    margin-right: 7.5vw;
}

.company-logo {
    float: right;
    right: 20px;
    position: absolute;
    height: max(5vw, 40px);
    width: max(5vw, 40px);
}

.cooper-logo {
    float: right;
    right: 20px;
    position: absolute;
    height: max(4vw, 40px);
    width: max(7vw, 60px);
}

.card-text {
    font-size: 0.85em;
}

.skills-container {
    font-size: 0.85em;
}

.skill-title {
    font-size: 1.15em;
}

.skill-title-sub {
    font-size: 1.15em;
    margin-top: 2vh;
}

.sub {
    margin-bottom: 2vh;
}

.stars {
    margin-right: 15vw;
}
.projects {
    font-family: 'Georgia';
}

.projects .projects-nav {
    color: black !important;
}

.container {
    font-size: min(max(1.5vw, 18px), 28px);
}


.project-card {
    background-color: rgb(85, 122, 149, 0.1);
}

.card-text {
    font-size: .85em;
}

.project-photo {
    margin-top: 20px;
    max-width: 100%;
}

.phone-photo {
    display: block;
    margin-top: 20px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-height: 50vh;
}

.github-logo {
    margin-top: 10px;
    height: auto;
    width: auto;
    max-width: 70px;
}

.github-logo:hover {
    margin-top: 10px;
    height: auto;
    width: auto;
    max-width: 70px;
    opacity: 0.5;
}

.amazon-stars {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    margin-top: 20px;
    max-height: 40vh;
}

.hive-logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 60%;
    margin-top: 20px;
    max-height: 30vh;
}
.foot {
    background-color: rgb(85, 122, 149, 0.3);
}

.logo {
    max-width: 40px;
    margin-right: 15px;
}

.logo:hover {
    -webkit-filter: invert(48%) sepia(0%) saturate(0%) hue-rotate(86deg) ;
            filter: invert(48%) sepia(0%) saturate(0%) hue-rotate(86deg) ;
}

