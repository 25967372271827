.foot {
    background-color: rgb(85, 122, 149, 0.3);
}

.logo {
    max-width: 40px;
    margin-right: 15px;
}

.logo:hover {
    filter: invert(48%) sepia(0%) saturate(0%) hue-rotate(86deg) ;
}
