.name {
    position: absolute;
    top: 30%;
    left: 15%;
    color: white;
    font-size: max(1.5em, 5vw);
}

.header-wrapper {
    position: relative;
}

.wall {
    width: 100%;
    height: 100%;
}