.home {
    font-family: "Georgia";
}

.home .home-nav {
    color : black !important;
}

.container {
    padding-top: 2em;
    font-size: min(max(1vw, 1px), 1px);
}

.title {
    font-size: 1.2em;
}

.about {
    padding-bottom: 3vh;
}

.buttons-container {
    margin-bottom: 3vh;
    display: flex;
}

.proj-btn {
    background-color: rgb(85, 122, 149, 0.3) !important;
    margin-bottom: 1vh;
}

.thoughts-btn {
    margin-left: 1vw;
    background-color: rgb(85, 122, 149, 0.3) !important;
    margin-bottom: 1vh;
}

.proj-btn:hover,
.thoughts-btn:hover {
    color: white !important;
    background-color: rgb(85, 122, 149) !important;
}

.card {
    border: 0em;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
}

.card-title {
    font-size: 1em;
    position: relative;
    margin-right: 7.5vw;
}

.company-logo {
    float: right;
    right: 20px;
    position: absolute;
    height: max(5vw, 40px);
    width: max(5vw, 40px);
}

.cooper-logo {
    float: right;
    right: 20px;
    position: absolute;
    height: max(4vw, 40px);
    width: max(7vw, 60px);
}

.card-text {
    font-size: 0.85em;
}

.skills-container {
    font-size: 0.85em;
}

.skill-title {
    font-size: 1.15em;
}

.skill-title-sub {
    font-size: 1.15em;
    margin-top: 2vh;
}

.sub {
    margin-bottom: 2vh;
}

.stars {
    margin-right: 15vw;
}