.projects {
    font-family: 'Georgia';
}

.projects .projects-nav {
    color: black !important;
}

.container {
    font-size: min(max(1.5vw, 18px), 28px);
}


.project-card {
    background-color: rgb(85, 122, 149, 0.1);
}

.card-text {
    font-size: .85em;
}

.project-photo {
    margin-top: 20px;
    max-width: 100%;
}

.phone-photo {
    display: block;
    margin-top: 20px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-height: 50vh;
}

.github-logo {
    margin-top: 10px;
    height: auto;
    width: auto;
    max-width: 70px;
}

.github-logo:hover {
    margin-top: 10px;
    height: auto;
    width: auto;
    max-width: 70px;
    opacity: 0.5;
}

.amazon-stars {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    margin-top: 20px;
    max-height: 40vh;
}

.hive-logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 60%;
    margin-top: 20px;
    max-height: 30vh;
}